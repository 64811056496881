import { graphql, useStaticQuery } from "gatsby"

interface UseMenuItemsProps {
  location?: string
  slug?: string
}

export const useMenuItems = ({ location, slug }: UseMenuItemsProps) => {
  const {
    allWpMenu: { nodes }
  } = useStaticQuery(graphql`
    {
      allWpMenu {
        nodes {
          slug
          locations
          menuFields {
            ctaButton {
              target
              title
              url
            }
          }
          menuItems {
            nodes {
              url
              label
              target
              parentDatabaseId
              cssClasses
              childItems {
                nodes {
                  id
                  label
                  url
                  childItems {
                    nodes {
                      id
                      label
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  if (location) {
    const menu = nodes && nodes.find(node => node.locations.includes(location))

    const menuItems =
      menu?.menuItems?.nodes &&
      menu.menuItems.nodes.filter(item => item.parentDatabaseId === 0)
    const menuFields = menu?.menuFields && menu.menuFields

    return { menuItems, menuFields }
  } else if (slug) {
    const menu = nodes.find(node => node.slug === slug)

    const menuItems =
      menu?.menuItems?.nodes &&
      menu.menuItems.nodes.filter(item => item.parentDatabaseId === 0)
    const menuFields = menu?.menuFields && menu.menuFields

    return { menuItems, menuFields }
  }
}

export default useMenuItems
