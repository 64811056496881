import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Parser from "html-react-parser"
import { Link } from "gatsby"

import { Typography, Button } from "@material-ui/core"

// import app components
import * as theme from "theme"
import { useMenuItems } from "../../hooks/useMenuItems"

const DesktopMenu = props => {
  const { menuItems, menuFields } = useMenuItems({ location: "MAIN_MENU" })

  return (
    <Menu {...props}>
      <Items>
        {menuItems &&
          menuItems?.map(({ id, url, label, childItems }) => (
            <MenuItem key={id}>
              {childItems && childItems.nodes.length ? (
                <>
                  <MenuLink to={url} activeClassName="active-menu-link">
                    <Typography
                      variant="subtitle2"
                      style={{ textTransform: "uppercase", fontWeight: "bold" }}
                      component="div"
                      children={label}
                    />
                  </MenuLink>

                  <SubMenu className="sub-menu">
                    {childItems.nodes.map((o, i) => (
                      <SubMenuItem
                        key={i}
                        to={url}
                        activeClassName="active-item"
                      >
                        <Typography
                          style={{
                            textTransform: "uppercase",
                            fontWeight: "bold"
                          }}
                          color="inherit"
                          component="div"
                          children={o.label}
                        />
                      </SubMenuItem>
                    ))}
                  </SubMenu>
                </>
              ) : (
                <MenuLink to={url}>
                  <Typography
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                    variant="subtitle2"
                    activeClassName="active-menu-link"
                    component="div"
                    children={label}
                  />
                </MenuLink>
              )}
            </MenuItem>
          ))}

        {menuFields?.ctaButton && (
          <Button
            style={{ color: "#fff", marginLeft: "15px" }}
            component="a"
            href={menuFields?.ctaButton?.url}
            target={menuFields?.ctaButton?.target}
          >
            {menuFields?.ctaButton?.title &&
              Parser(menuFields?.ctaButton?.title)}
          </Button>
        )}
      </Items>
    </Menu>
  )
}

export default DesktopMenu

const Menu = styled.div`
  display: flex;
  a {
    text-decoration: none;
  }

  .active-menu-link {
    color: ${theme.colors.primary};
  }
`

const Items = styled.div`
  display: flex;
  align-items: center;
`

const MenuItem = styled.div`
  display: flex;
  position: relative;

  &:hover {
    .sub-menu {
      opacity: 1;
      pointer-events: all;
    }
  }
  .sub-menu {
    opacity: 0;
    pointer-events: none;
  }
`

const MenuLink = styled(Link)`
  padding: 5px 17px;
  /* margin-left: 40px; */
  color: ${theme.colors.secondary};

  &:hover {
    color: ${theme.colors.primary};
  }
`

const SubMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: -20px;
  background: ${theme.colors.primary};
  padding: 10px 0;

  .active-item {
    color: white;
  }
`

const SubMenuItem = styled(Link)`
  padding: 10px 30px;
  flex-shrink: 0;
  white-space: nowrap;
  color: #e4f2f5;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
  > div {
    font-size: 14px;
  }
`
