import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
// import Parser from "html-react-parser"

import { TextField, Button, Typography } from "@material-ui/core"
import CircularProgress from "@material-ui/core/CircularProgress"

import useFetch from "../../hooks/useFetch"

export default props => {
  const {
    newsletter,
    location: { href: sourceUrl }
  } = props

  const [values, setValues] = useState({ input_2: sourceUrl })

  const [state, setState] = useState(null)

  const formId = 2
  const endpoint = `${process.env.GATSBY_WP}/wp-json/gf/v2/forms/${formId}/submissions`

  const [submitForm, { loading }] = useFetch(endpoint, {
    method: "POST",
    "Content-Type": "application/x-www-form-urlencoded",
    onCompleted: data => {
      if (data?.is_valid) {
        setState(`completed`)
      }
    },
    onError: err => {
      if (
        err?.validation_messages &&
        err.validation_messages[`1`].startsWith(
          `This field requires a unique entry`
        )
      ) {
        setState("error-already-signed-up")
      } else {
        setState("error-something-went-wrong")
      }
    }
  })

  const handleSubmit = e => {
    e.preventDefault() // prevents the default form submit action

    if (!values[`input_1`]) {
      return
    }

    const formData = new FormData()
    Object.keys(values).map(key => formData.append(key, values[key])) // append values to formData

    submitForm({ body: formData })
  }

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }

  return (
    <>
      {!state || state === "default" ? (
        <>
          {newsletter?.headline && (
            <Typography variant="h1" gutterBottom color="inherit">
              {newsletter.headline}
            </Typography>
          )}

          {newsletter?.body && (
            <Typography
              variant="body1"
              color="inherit"
              style={{ marginBottom: "40px" }}
            >
              {newsletter.body}
            </Typography>
          )}

          <FormContainer onSubmit={handleSubmit}>
            <TextField
              id="zip"
              name="input_3"
              onBlur={handleChange}
              type="text"
              variant="outlined"
              placeholder="Postal code"
              fullWidth
              style={{
                marginBottom: "20px",
                background: "#fff",
                borderRadius: "8px 8px 8px 8px"
              }}
            />

            <span>
              <EmailField
                onBlur={handleChange}
                name="input_1"
                onKeyDown={e =>
                  e.key === "Enter" && handleChange(e) && handleSubmit()
                }
                fullWidth
                variant="outlined"
                placeholder="Your email address"
                type="email"
                disabled={loading}
              />
              <SubmitButton type="submit" disabled={loading}>
                <ButtonText loading={loading}>Submit</ButtonText>
                <LoaderContainer loading={loading}>
                  <CircularProgress size={25} color="inherit" />
                </LoaderContainer>
              </SubmitButton>
            </span>
          </FormContainer>
        </>
      ) : state === "error-already-signed-up" ? (
        <>
          <Typography variant="h1" gutterBottom color="inherit">
            Already signed up
          </Typography>
          <Typography variant="body1" color="inherit">
            You have already signed up to the newsletter.
          </Typography>
        </>
      ) : state === "error-something-went-wrong" ? (
        <>
          <Typography variant="h1" gutterBottom color="inherit">
            Error
          </Typography>
          <Typography variant="body1" color="inherit">
            Something went wrong. Please try again.
          </Typography>
        </>
      ) : (
        state === "completed" && (
          <>
            <Typography variant="h1" gutterBottom>
              Success
            </Typography>
            <Typography variant="body1" color="inherit">
              You have successfully signed up to the newsletter!
            </Typography>
          </>
        )
      )}
    </>
  )
}

const FormContainer = styled.form`
  display: block;
  width: 100%;
  > span {
    display: flex;
  }
`

const EmailField = styled(TextField)`
  background: #fff;
  border-radius: 8px 0px 0px 8px;
  .MuiOutlinedInput-root {
    border-radius: 8px 0px 0px 8px;
  }
`

const SubmitButton = styled(Button)`
  &:hover {
    background-color: #ca3232;
  }
  background: #ca3232;
  color: #fff;
  border-radius: 0px 8px 8px 0px;
`

const LoaderContainer = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  opacity: 0;
  ${({ loading }) =>
    loading &&
    css`
      opacity: 1;
    `}
`

const ButtonText = styled.span`
  ${({ loading }) =>
    loading &&
    css`
      opacity: 0;
    `}
`
