import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

// import app components
import Header from "components/header"
import Footer from "components/footer"
import SEO from "components/SEO"

export default props => {
  const { pageContext, location } = props

  return (
    <Container>
      <SEO {...pageContext} {...location} />
      <div>
        <Header />
        <Main>{props?.children}</Main>
      </div>
      <Footer location={props?.location} />
    </Container>
  )
}

const Container = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  max-width: 1920px;
  margin: 0 auto 0;
  background: #fff;
  position: relative;
  overflow-x: hidden;
  #jointhefight {
    border-top: 120px solid transparent;
    margin-top: -120px;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
  }
`

const Main = styled.main``
