import React from "react"
import styled from "@emotion/styled"

import Parser from "html-react-parser"
import { Link } from "gatsby"

import { Typography } from "@material-ui/core"

import { useMenuItems } from "../../hooks/useMenuItems"

export default () => {
  const { menuItems } = useMenuItems({ location: "FOOTER" })

  return (
    <Container>
      {menuItems &&
        menuItems.map(item => {
          return (
            <div key={item.id}>
              <MenuItem to={item.url}>
                <Typography gutterBottom variant="body1" color="inherit">
                  {item?.label && Parser(item.label)}
                </Typography>
              </MenuItem>
            </div>
          )
        })}
    </Container>
  )
}

const Container = styled.div`
  @media (max-width: 959px) {
    margin: 30px 0;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
`
const MenuItem = styled(Link)`
  color: white;
`
