import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Link } from "gatsby"

import { Typography, Button } from "@material-ui/core"
// import MenuItems from "./MenuItems"

// import app components
import { useStore } from "store"
import * as theme from "theme"

import { useMenuItems } from "../../hooks/useMenuItems"

const MobileMenu = props => {
  const [
    {
      appState: { menu }
    },
    dispatch
  ] = useStore()

  const { menuItems, menuFields } = useMenuItems({ location: "MAIN_MENU" })

  return (
    <Menu {...props} menuState={menu}>
      {menuItems &&
        menuItems.map(({ id, url, active, activeParent, label }) => (
          <Item
            key={id}
            onClick={() => dispatch({ type: "SET_MENU", payload: false })}
            to={url}
            className={active || activeParent ? "active" : ""}
          >
            <Title variant="subtitle1" children={label} />
          </Item>
        ))}

      {menuFields?.ctaButton?.url && (
        <Button
          component={Link}
          to={menuFields?.ctaButton.url}
          variant="contained"
          style={{ margin: "30px auto" }}
          onClick={() => dispatch({ type: "SET_MENU", payload: false })}
        >
          {menuFields?.ctaButton.title}
        </Button>
      )}
    </Menu>
  )
}

export default MobileMenu

const menuClose = css`
  transform: translateX(120%);
  opacity: 0;
`
const menuOpen = css`
  transform: translateX(0);
  opacity: 1;
`

const Menu = styled.div`
  ${props => (!!props.menuState ? menuOpen : menuClose)}
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 300;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  background: ${theme.colors.primary};
  padding-top: 40px;
  top: 100px;

  @media screen and (min-width: ${theme.mui.breakpoints.values.sm}px) {
    top: 100px;
  }
`

const ItemCSS = `
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  width: 100%;
  text-align: center;
`

const Item = styled(Link)`
  ${ItemCSS}
`

const Title = styled(Typography)`
  padding: 5px 0;
  color: white;
  text-transform: uppercase;
`
