import { useState } from "react"

export const useFetch = (endpoint, options = {}) => {
  const { onError = null, onCompleted = null, ...restOptions } = options

  const [state, setState] = useState({
    error: null,
    data: null,
    loading: false,
    called: false
  })

  const callback = async args => {
    setState({
      ...state,
      called: true,
      loading: true,
      error: null
    })

    // const params = args && createQueryString(args)

    await fetch(endpoint, {
      method: "get",
      ...restOptions,
      ...args
    })
      .then(async response => {
        const json = await response.json()

        setState({
          ...state,
          loading: false,
          data: response.status === 200 ? json : null,
          called: true,
          error: response.status !== 200 ? json : null
        })

        if (response.status === 200) {
          onCompleted && typeof onCompleted === `function` && onCompleted(json)
        } else {
          onError && typeof onError === `function` && onError(json)
        }
      })
      .catch(error => {
        setState({ ...state, error, loading: false, called: true })
        onError && typeof onError === `function` && onError(error)
      })
  }

  return [callback, state]
}

export default useFetch
