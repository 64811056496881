import React, { useEffect } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import HamburgerMenu from "react-hamburger-menu"

import { AppBar, LinearProgress } from "@material-ui/core"

import AacLogo from "../../assets/aac-logo.svg"

import DesktopMenu from "components/menu/DesktopMenu"
import MobileMenu from "components/menu/MobileMenu"
import Edges from "components/edges"

import { useStore } from "store"
import { media } from "utils"
import * as theme from "theme"

export default props => {
  const { path } = props

  const [
    {
      appState: { progress, menu, snackbar }
    },
    dispatch
  ] = useStore()

  useEffect(() => {
    dispatch({ type: "SET_MENU", payload: false })
  }, [path])

  return (
    <>
      <StyledAppBar position="fixed">
        <Edges size="lg">
          <Nav>
            <LogoContainer>
              <Link
                to="/"
                onClick={() => dispatch({ type: "SET_MENU", payload: false })}
              >
                <StyledAacLogo>
                  <AacLogo style={{ position: "absolute", top: 0 }} />
                </StyledAacLogo>
                <MobileStyledAacLogo>
                  <AacLogo style={{ height: "71px" }} />
                </MobileStyledAacLogo>
              </Link>
            </LogoContainer>

            <Right>
              <DesktopMenu
                className="desktop-menu"
                style={{ marginRight: "10px" }}
              />

              <HamburgerMenuContainer
                onClick={() => dispatch({ type: "TOGGLE_MENU" })}
              >
                <HamburgerMenu
                  color={theme.colors.primary}
                  isOpen={menu}
                  width={18}
                  height={15}
                  strokeWidth={1}
                  menuClicked={() => ""}
                />
              </HamburgerMenuContainer>
            </Right>
          </Nav>
        </Edges>
      </StyledAppBar>

      <MobileMenu />
      <HiddenHeader />
      {progress && <StyledLinearProgress color="primary" />}
    </>
  )
}

const MobileStyledAacLogo = styled.div`
  @media (min-width: 1267px) {
    height: "71px";

    display: none;
  }
`
const StyledAacLogo = styled.div`
  @media (max-width: 1268px) {
    height: 100%;
    display: none;
  }
`

const HiddenHeader = styled.div`
  height: 92px;
  position: relative;
  width: 100%;
  @media (max-width: 1000px) {
    height: 30px;
  }
`
const Right = styled.div`
  display: flex;
  align-items: center;
`

const StyledAppBar = styled(AppBar)`
  && {
    background: #fff;
    padding: 12px 0;
    @media (min-width: 1268px) {
      padding: 24px 0;
    }
    display: flex;
    align-items: center;
    z-index: 999;
    box-shadow: none;
  }
`

const LogoContainer = styled.div`
  a {
    text-decoration: none;
  }
`

const HamburgerMenuContainer = styled.div`
  @media (min-width: 1268px) {
    display: none;
  }
  padding: 15px;
  margin-right: -15px;
  cursor: pointer;
`

const StyledLinearProgress = styled(LinearProgress)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9000;
`

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .desktop-menu {
    @media (max-width: 1268px) {
      display: none;
    }
  }
`
