import { createTheme } from "@material-ui/core/styles"
import colors from "./colors"

export default createTheme({
  typography: {
    fontFamily: "Montserrat, sans-serif",
    h1: {
      fontSize: "38px",
      lineHeight: 1.2,
      fontWeight: 600
    },
    h2: {
      fontSize: "28px",
      fontWeight: 600,
      lineHeight: 1.25
    },
    h3: {
      fontSize: "21px",
      lineHeight: 1.35,
      fontWeight: 600
    },
    h4: {
      fontFamily: "Montserrat",
      fontSize: "14px",
      lineHeight: "21px",
      fontWeight: 600
    },
    h5: {},
    h6: {},
    subtitle1: {},
    subtitle2: {
      fontFamily: "Montserrat, sans-serif",
      fontSize: "14px"
    },
    body1: {
      fontFamily: "Lato",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "28px",
      color: "#282829"
    },
    body2: { fontFamily: "Lato, sans-serif" },
    body3: {
      fontFamily: "Lato",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "28px",
      color: "#FFFFFF"
    },
    caption: {},
    button: {}
  },
  palette: {
    primary: {
      main: colors.primary,
      contrastText: "white"
    },
    secondary: {
      main: colors.secondary
    }
  },
  // https://material-ui.com/customization/themes/
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: "0.81rem"
      }
    },
    MuiButton: {
      contained: {
        textAlign: "center",
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "16px",
        padding: "14px 20px",
        letterSpacing: "1px",
        backgroundColor: "#fff",
        color: colors.primary
      },
      root: {
        "&:hover": {
          backgroundColor: colors.primary
        }
      },
      text: {
        textAlign: "center",
        fontFamily: "Montserrat, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "13px",
        lineHeight: "16px",
        padding: "14px 20px",
        letterSpacing: "1px",
        background: colors.primary
      }
    }
  }
})
