import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { useStaticQuery, graphql } from "gatsby"

import { AppBar, Typography } from "@material-ui/core"

import FacebookIcon from "../../assets/social/facebook.svg"
import InstaIcon from "../../assets/social/insta.svg"
import TwitterIcon from "../../assets/social/x.svg"
import FooterGreyBg from "../../assets/footer_grey_bg.svg"
import YoutubeIcon from "../../assets/social/youtube.svg"
import LinkedinIcon from "../../assets/social/linkedin.svg"

// import app components
import Edges from "components/edges"
import NewsletterSignup from "./NewsletterSignup"
import FooterMenu from "./FooterMenu"

import colors from "../../theme/colors"

export default props => {
  const {
    acf: { newsletter, social },
    title
  } = useSiteOptions()

  return (
    <Footer
      position="static"
      color="secondary"
      isHomePage={
        props?.location?.pathname === "/" ||
        props?.location?.pathname === "/news" ||
        props?.location?.pathname === "/take-action/" ||
        props?.location?.pathname === "/take-action"
      }
    >
      {!(
        props?.location?.pathname === "/" ||
        props?.location?.pathname === "/news" ||
        props?.location?.pathname === "/take-action/" ||
        props?.location?.pathname === "/take-action" ||
        typeof window === `undefined`
      ) && (
        <StyledFooterGreyBg
          style={{
            position: "absolute",
            bottom: "100%",
            left: 0,
            width: "1920px",
            height: "auto"
          }}
        />
      )}
      <Upper>
        <Edges size="lg">
          <InnerContainer>
            <Newsletter>
              <NewsletterSignup
                location={props?.location}
                newsletter={newsletter}
              />
            </Newsletter>
            <Social>
              {social?.headline && (
                <Typography variant="h1" gutterBottom>
                  {social.headline}
                </Typography>
              )}

              {social?.body && (
                <Typography variant="body1" style={{ marginBottom: "40px" }}>
                  {social.body}
                </Typography>
              )}

              {social?.facebookUrl && (
                <a
                  target="_blank"
                  href={social.facebookUrl}
                  style={{ marginRight: "60px" }}
                >
                  <FacebookIcon />
                </a>
              )}

              {social?.instagramUrl && (
                <a
                  target="_blank"
                  href={social.instagramUrl}
                  style={{ marginRight: "60px" }}
                >
                  <InstaIcon />
                </a>
              )}

              {social?.xUrl && (
                <a target="_blank" href={social.xUrl}>
                  <TwitterIcon
                    style={{
                      position: "relative",
                      fill: "#EF3B3B",
                      width: "40px",
                      height: "40px",
                      marginRight: "60px"
                    }}
                  />
                </a>
              )}
              {social?.youtubeUrl && (
                <a target="_blank" href={social.youtubeUrl}>
                  <YoutubeIcon
                    style={{
                      position: "relative",
                      fill: "#EF3B3B",
                      width: "40px",
                      height: "40px",
                      marginRight: "60px"
                    }}
                  />
                </a>
              )}
              {social?.linkedinUrl && (
                <a target="_blank" href={social.linkedinUrl}>
                  <LinkedinIcon
                    style={{
                      position: "relative",
                      fill: "#EF3B3B",
                      width: "40px",
                      height: "40px"
                    }}
                  />
                </a>
              )}
            </Social>
          </InnerContainer>
        </Edges>
      </Upper>

      <Edges size="lg">
        <Copyright>
          <Typography style={{ fontFamily: "Montserrat", color: "#fff" }}>
            <strong>
              &copy; {new Date().getFullYear()} {title}
            </strong>
            <br /> All rights reserved.
          </Typography>

          <div>
            <FooterMenu />
            <HyperLink href="https://jambaree.com" target="_blank">
              <Typography style={{ fontFamily: "Montserrat", color: "#fff" }}>
                Website Donated by Jambaree
              </Typography>
            </HyperLink>
          </div>
        </Copyright>
      </Edges>
    </Footer>
  )
}

const StyledFooterGreyBg = styled(FooterGreyBg)`
  @media (max-width: 800px) {
    display: "none";
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 100%;
    @media (min-width: 800px) {
      width: 50%;
    }
  }
`

const Newsletter = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  @media (min-width: 800px) {
    padding-right: 80px;
  }
`

const Social = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  @media (min-width: 800px) {
    padding-left: 80px;
  }
  position: relative;
  background: #fff;
  color: ${colors.secondary};
  &:after {
    content: "";
    background: #fff;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 100%;
    top: 0;
  }
  @media (max-width: 799px) {
    &:before {
      content: "";
      background: #fff;
      position: absolute;
      height: 100%;
      width: 100%;
      right: 100%;
      top: 0;
    }
  }
`

const Footer = styled(AppBar)`
  z-index: 0;
  background: ${colors.grey};
  position: relative;
  ${({ isHomePage }) =>
    !isHomePage &&
    css`
      @media (min-width: 801px) {
        margin-top: 112px;
      }
    `}
`

const HyperLink = styled.a`
  color: inherit;
  text-decoration: none;
`

const Upper = styled.div`
  background: ${colors.primary};
  display: flex;
  overflow: hidden;
`

const Copyright = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 0;
`

const useSiteOptions = () => {
  const {
    wp: {
      generalSettings: { title },
      siteOptions: { acf }
    }
  } = useStaticQuery(graphql`
    {
      wp {
        generalSettings {
          title
        }
        siteOptions {
          acf {
            newsletter {
              headline
              body
            }
            social {
              headline
              body
              facebookUrl
              xUrl
              instagramUrl
              youtubeUrl
              linkedinUrl
            }
          }
        }
      }
    }
  `)

  return { title, acf }
}
