const colors = {
  primary: "#EF3B3B",
  secondary: "#282829",
  grey: "#77787B",
  lightgrey: "#E0E0E0",
  success: "green",
  error: "red"
}

export default colors
